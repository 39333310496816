// composables/useFilters.js
export const useFilters = () => {
  const textLengthFilter = (text = "", length, suffix = "...") => {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  };

  const typeToArabicFilter = (text = "") => {
    switch (text) {
      case "course":
        return "مادة ";
      case "ebook":
        return "كتاب إلكتروني";
      case "bundle":
        return "باقة";
      case "article":
        return "مقال";
      case "publication":
        return "إصدار";
      case "books":
      case "book":
        return "كتاب";
      case "cards":
      case "card":
        return "بطاقة";
      case "albums":
      case "album":
        return "إلبوم";
      case "auto-complete":
        return "قبول تلقائي";
      case "accepted":
        return "مقبول";
      case "pending":
        return "قيد المراجعة";
      case "rejected":
        return "مرفوض";
      case "paytabs_credit_card":
        return "كريدت كارد";
      case "credit_card":
        return "كريدت كارد";
      case "bank_transfer":
        return "حوالة بنكية";
      case "pending_payment":
        return "بانتظار الدفع";
      case "free_order":
        return "طلب مجاني";
      case "payment_rejected":
        return "فشل الدفع";
      case "refunded":
        return "مسترجع";
      case "spotii":
        return "سبوتي";
      case "spotii-pending_payment":
        return "بانتظار الدفع بسبوتي";
      case "spotii-auto-complete":
        return "دفع مقبول بسبوتي";
      case "spotii-payment_rejected":
        return " دفع مرفوض بسبوتي";
      case "tamara":
        return "تمارا";
      case "tamara-pending":
        return "بانتظار الدفع بتمارا";
      case "tamara-accepted":
        return "دفع مقبول بتمارا";
      case "tamara-rejected":
        return "دفع مرفوض بتمارا";
      case "tabby":
        return "تابي";
      case "tabby-pending":
        return "بانتظار الدفع بتابي";
      case "tabby-accepted":
        return "دفع مقبول بتابي";
      case "tabby-rejected":
        return "دفع مرفوض بتابي";
      case "apple_pay":
        return "ابل باي";
      case "apple_pay-accepted":
        return "دفع مقبول بـ ابل باي";
      case "apple_pay-pending":
        return "بانتظار الدفع بـ ابل باي";
      case "apple_pay-rejected":
        return "دفع مرفوض بـ ابل باي";
      case "course_attachments":
        return "ملحقات";
      default:
        return text;
    }
  };

  return {
    textLengthFilter,
    typeToArabicFilter
  };
};
